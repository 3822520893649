<template>
  <div id="page-jobs">

    <memlist-card>
      <h5 class="m-4">Körningar</h5>

      <div>
        <JobTable 
          type="ALL"
          class="mb-8"
        />
      </div>
    </memlist-card>

  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import JobTable from '@/view/pages/ml/jobs/JobTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'pages',
  mixins: [ toasts ],
  components: {
    JobTable
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    
  },
  data() {
    return {
      
    };
  },
  watch: {
    
  },
  methods: {
    
  }
};
</script>
